<template>
  <b-table-simple
    bordered
    responsive
    sticky-header
    style="max-height: 65vh; font-size: 12px"
  >
    <colgroup>
      <col style="width: 7%">
      <col style="width: 10%">
      <col>
      <col style="width: 10%">
      <col style="width: 12%">
      <col style="width: 7%">
      <col style="width: 8%">
      <col style="width: 7%">
      <col style="width: 8%">
      <col style="width: 7%">
    </colgroup>
    <b-thead head-variant="light">
      <b-tr>
        <b-th>ID</b-th>
        <b-th>활용데이터</b-th>
        <b-th>제목</b-th>
        <b-th>게시 시작일</b-th>
        <b-th>진료과</b-th>
        <b-th>총 노출 수</b-th>
        <b-th>{{ searchType === 1 ? '총 노출 처수' : '총 노출 명수' }}</b-th>
        <b-th>총 클릭 수</b-th>
        <b-th>{{ searchType === 1 ? '총 클릭 처수' : '총 클릭 명수' }}</b-th>
        <b-th>댓글 수</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="summary in data">
        <template v-for="(dept, index) in summary.summaryDepts">
          <b-tr
            :key="`summary-${summary.id}-${index}`"
          >
            <b-td
              v-if="index === 0"
              :rowspan="summary.summaryDepts.length + 1"
            >
              {{ summary.id }}
            </b-td>
            <b-td
              v-if="index === 0"
              :rowspan="summary.summaryDepts.length + 1"
            >
              {{ summary.utilData }}
            </b-td>
            <b-td
              v-if="index === 0"
              :rowspan="summary.summaryDepts.length + 1"
            >
              {{ summary.title }}
            </b-td>
            <b-td
              v-if="index === 0"
              :rowspan="summary.summaryDepts.length + 1"
            >
              {{ summary.startDate | $dateFormatter('YYYY-MM-DD') }}
            </b-td>
            <b-td>{{ dept.medicalDept }}</b-td>
            <b-td>{{ dept.totalOpenCount | $numberFormatter }}</b-td>
            <b-td>{{ dept.groupOpenCount | $numberFormatter }}</b-td>
            <b-td>{{ dept.totalClickCount | $numberFormatter }}</b-td>
            <b-td>{{ dept.groupClickCount | $numberFormatter }}</b-td>
            <b-td>{{ dept.commentCount | $numberFormatter }} </b-td>
          </b-tr>
        </template>
        <b-tr
          :key="`summary-total-${summary.id}`"
        >
          <b-td
            style="text-align: center;"
          >
            소계
          </b-td>
          <b-td>{{ summary.summaryDepts.reduce((f, l) => f + l.totalOpenCount, 0) | $numberFormatter }}</b-td>
          <b-td>{{ summary.summaryDepts.reduce((f, l) => f + l.groupOpenCount, 0) | $numberFormatter }}</b-td>
          <b-td>{{ summary.summaryDepts.reduce((f, l) => f + l.totalClickCount, 0) | $numberFormatter }}</b-td>
          <b-td>{{ summary.summaryDepts.reduce((f, l) => f + l.groupClickCount, 0) | $numberFormatter }}</b-td>
          <b-td>{{ summary.summaryDepts.reduce((f, l) => f + l.commentCount, 0) | $numberFormatter }}</b-td>
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    searchType: {
      type: Number,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
    })
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}
</style>
